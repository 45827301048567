import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import {
  ConsultationTherapistQuery,
  ContentfulTherapist,
  MatchesQuery,
  TherapistQuery,
} from '../graphql-types';
import { navigate, RouteComponentProps } from '@reach/router';
import { Badge, Col, Container, Jumbotron, Row } from 'reactstrap';
import MainContainer from '../components/MainContainer';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faComments,
  faEnvelope,
  faHeart,
  faMapMarkerAlt,
  faMobile,
  faPhone,
  faPlay,
  faPoundSign,
  faUser,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import GoogleMapReact from 'google-map-react';
import { MainButton } from '../components/Button';
import Modali, { useModali } from 'modali';
import { ModalTherapistCard } from '../components/TherapistCard';
import ReactPlayer from 'react-player';
import { theme } from '../theme';
import Image from 'gatsby-image';
import Text from '../reusecore/src/elements/Text/text';
import { flatMap } from 'lodash-es';
import ThemeButton from '../reusecore/src/elements/Button/button';
import { HorizontalRule } from './therapist-profile';
import Box from '../reusecore/src/elements/Box/index';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { getFirebaseAuth, getFirebaseFirestore } from '../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getLogger } from '@graphql-codegen/cli/utils/logger';
import { getDistanceFromLatLonInKm } from '../utils/match';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { articleRichTextOptions } from '../utils/contentful';

export interface ConsultationTherapistTemplateProps {
  data: ConsultationTherapistQuery;
}

export type TherapyViaOptions =
  | 'Face-to-face'
  | 'Video'
  | 'SMS Messaging'
  | 'Email';

export const getTherapyViaIconName = (
  therapyViaOption: TherapyViaOptions
): IconDefinition => {
  switch (therapyViaOption) {
    case 'Email':
      return faEnvelope;
    case 'Face-to-face':
      return faComments;
    case 'SMS Messaging':
      return faMobile;
    case 'Video':
      return faVideo;
    default:
      throw new Error('No icon found.');
  }
};

const PlayerOverlay = styled.div<{ playing: boolean }>`
  position: absolute;
  top: 0;
  opacity: ${props => (props.playing ? 0 : 0.25)};
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FIXED_SPECIALISMS: string[] = [
  'Anxiety',
  'Depression',
  'Stress',
  'Minority Stress',
];

const ConsultationTherapistTemplate: React.FunctionComponent<ConsultationTherapistTemplateProps> = ({
  data,
}): JSX.Element => {
  const { contentfulTherapist: therapistInfo } = data;

  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);

  return (
    <Layout contrast>
      <Container
        fluid
        style={{ padding: 0, backgroundColor: theme.colors.sand }}
      >
        <Container>
          <Row>
            <Col
              sm={{ size: 6, offset: 3 }}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                flexBox
                flexDirection="column"
                alignItems="center"
                width="100%"
                pt={4}
              >
                <Image
                  // @ts-ignore
                  fluid={therapistInfo.profilePhoto.fluid}
                  style={{
                    height: theme.sizes[11],
                    width: '100%',
                    borderRadius: theme.radii[3],
                  }}
                />
                <Text
                  fontFamily="heading"
                  fontWeight={3}
                  fontSize={3}
                  pt={3}
                  pb={1}
                  lineHeight="normal"
                  m={0}
                >
                  {therapistInfo.fullName}
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={1}
                  pb={3}
                  lineHeight="normal"
                  m={0}
                >
                  {therapistInfo.title}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  textAlign="center"
                  pb={3}
                >
                  {therapistInfo.headline.headline}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Box
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    flexBox
                    alignItems="center"
                  >
                    <Box
                      flexBox
                      width={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faPoundSign} size="lg" />
                    </Box>
                    <Text
                      fontFamily="body"
                      fontWeight={3}
                      fontSize={0}
                      lineHeight="normal"
                      letterSpacing="tracked"
                      m={0}
                      ml={3}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Price
                    </Text>
                  </Box>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    £{therapistInfo.sessionPricing} / session
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Box
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    flexBox
                    alignItems="center"
                  >
                    <Box
                      flexBox
                      width={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faHeart} size="lg" />
                    </Box>
                    <Text
                      fontFamily="body"
                      fontWeight={3}
                      fontSize={0}
                      lineHeight="normal"
                      letterSpacing="tracked"
                      m={0}
                      ml={3}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Sexuality
                    </Text>
                  </Box>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {therapistInfo.sexuality}
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Box
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    flexBox
                    alignItems="center"
                  >
                    <Box
                      flexBox
                      width={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faUser} size="lg" />
                    </Box>
                    <Text
                      fontFamily="body"
                      fontWeight={3}
                      fontSize={0}
                      lineHeight="normal"
                      letterSpacing="tracked"
                      m={0}
                      ml={3}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Gender
                    </Text>
                  </Box>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {therapistInfo.gender}
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Box
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    flexBox
                    alignItems="center"
                  >
                    <Box
                      flexBox
                      width={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faComments} size="lg" />
                    </Box>
                    <Text
                      fontFamily="body"
                      fontWeight={3}
                      fontSize={0}
                      lineHeight="normal"
                      letterSpacing="tracked"
                      m={0}
                      ml={3}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Therapy Via
                    </Text>
                  </Box>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {therapistInfo.therapyVia.reduce((a, b) => a + ', ' + b)}
                  </Text>
                </Box>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <Text
                  fontFamily="body"
                  fontWeight={3}
                  fontSize={1}
                  lineHeight="normal"
                  letterSpacing="tracked"
                  m={0}
                  pb={0}
                  style={{
                    textTransform: 'uppercase',
                    alignSelf: 'flex-start',
                  }}
                >
                  About
                </Text>
                <Box position="relative" mb={3}>
                  {therapistInfo.profileVideo && (
                    <>
                      <ReactPlayer
                        url={therapistInfo.profileVideo.file.url}
                        playing={videoPlaying}
                        className="w-100"
                        config={{ file: { attributes: { type: 'video/mp4' } } }}
                      />
                      <PlayerOverlay
                        onClick={() => {
                          setVideoPlaying(!videoPlaying);
                        }}
                        playing={videoPlaying}
                      >
                        <FontAwesomeIcon
                          icon={faPlay}
                          color="white"
                          size="3x"
                        />
                      </PlayerOverlay>
                    </>
                  )}
                </Box>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={3}
                >
                  {documentToReactComponents(
                    therapistInfo.richTextBio.json,
                    articleRichTextOptions
                  )}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="center"
                width="100%"
              >
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={3}
                    fontSize={0}
                    lineHeight="normal"
                    letterSpacing="tracked"
                    m={0}
                    pb={2}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Therapy Types
                  </Text>
                </Box>
                <Box flexBox width="100%" pb={4}>
                  {[
                    ...(therapistInfo.therapyTypes || []),
                    ...(therapistInfo.additionalTherapyTypes || []),
                  ].map(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        padding: '5px 10px',
                        marginRight: theme.space[2],
                        backgroundColor: theme.colors.primary,
                        marginBottom: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontWeight={1}
                        fontSize={0}
                        lineHeight="normal"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={3}
                    fontSize={0}
                    lineHeight="normal"
                    letterSpacing="tracked"
                    m={0}
                    pb={2}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Specialisms
                  </Text>
                </Box>
                <Box flexBox flexWrap="wrap" width="100%" pb={4}>
                  {[
                    ...(therapistInfo.specialisms || []),
                    ...(therapistInfo.additionalSpecialisms || []),
                    ...FIXED_SPECIALISMS,
                  ].map(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        padding: '5px 10px',
                        marginRight: theme.space[2],
                        backgroundColor: theme.colors.primary,
                        marginBottom: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontWeight={1}
                        fontSize={0}
                        lineHeight="normal"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={3}
                    fontSize={0}
                    lineHeight="normal"
                    letterSpacing="tracked"
                    m={0}
                    pb={2}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Issues Worked With
                  </Text>
                </Box>
                <Box flexBox flexWrap="wrap" width="100%" pb={4}>
                  {therapistInfo.issuesWorkedWith.map(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        padding: '5px 10px',
                        marginRight: theme.space[2],
                        backgroundColor: theme.colors.primary,
                        marginBottom: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontWeight={1}
                        fontSize={0}
                        lineHeight="normal"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={3}
                    fontSize={0}
                    lineHeight="normal"
                    letterSpacing="tracked"
                    m={0}
                    pb={2}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Qualifications
                  </Text>
                </Box>
                <Box
                  flexBox
                  flexDirection="column"
                  flexWrap="wrap"
                  width="100%"
                  pb={4}
                >
                  {therapistInfo.qualifications.map(x => (
                    <Text
                      key={x}
                      fontFamily="body"
                      fontWeight={1}
                      fontSize={0}
                      lineHeight="normal"
                      m={0}
                      as="li"
                    >
                      {x}
                    </Text>
                  ))}
                </Box>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <Text
                  fontFamily="body"
                  fontWeight={3}
                  fontSize={1}
                  lineHeight="normal"
                  letterSpacing="tracked"
                  m={0}
                  pb={3}
                  style={{ textTransform: 'uppercase' }}
                >
                  Payment
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  <span style={{ fontWeight: theme.fontWeights[3] }}>
                    £{therapistInfo.sessionPricing}
                  </span>{' '}
                  per session
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  {flatMap(
                    therapistInfo.pricingConditions.pricingConditions.split(
                      '\n'
                    ),
                    (text, i) => [i > 0 && <br />, text]
                  )}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <Text
                  fontFamily="body"
                  fontWeight={3}
                  fontSize={1}
                  lineHeight="normal"
                  letterSpacing="tracked"
                  m={0}
                  pb={3}
                  style={{ textTransform: 'uppercase' }}
                >
                  Find Me At
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  {therapistInfo.address.address}
                </Text>
                <Box height={theme.sizes[9]} width="100%" pb={5}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: 'AIzaSyCkHaE09Mwm4aYwC0z_q-eEmna-0zHje6Y',
                      language: 'en-GB',
                      region: 'uk',
                    }}
                    defaultCenter={{
                      lat: therapistInfo.location.lat,
                      lng: therapistInfo.location.lon,
                    }}
                    zoom={14}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      color="red"
                      size="2x"
                    />
                  </GoogleMapReact>
                </Box>
              </Box>
            </Col>
          </Row>
          {!!therapistInfo.consultationId && (
            <Row>
              <Col sm={{ offset: 2, size: 8 }}>
                <Box
                  flexBox
                  flexDirection="column"
                  alignItems="center"
                  width="100%"
                  pb={4}
                >
                  <ThemeButton
                    py={2}
                    width={9}
                    onClick={() => {
                      window.location.assign(
                        `https://helsaconsultation.com/${therapistInfo.consultationId}`
                      );
                    }}
                  >
                    Book Consultation
                  </ThemeButton>
                </Box>
              </Col>
            </Row>
          )}
        </Container>
      </Container>
    </Layout>
  );
};

export default ConsultationTherapistTemplate;

export const pageQuery = graphql`
  query ConsultationTherapist($slug: String!) {
    contentfulTherapist(slug: { eq: $slug }) {
      slug
      therapyTypes
      additionalTherapyTypes
      specialisms
      additionalSpecialisms
      dateOfBirth
      sexuality
      gender
      ethnicity
      sessionPricing
      profilePhoto {
        fluid(maxWidth: 1000) {
          src
        }
      }
      profileVideo {
        file {
          url
        }
      }
      fullName
      therapyVia
      headline {
        headline
      }
      title
      bio {
        bio
      }
      richTextBio {
        json
      }
      issuesWorkedWith
      qualifications
      consultationFee
      pricingConditions {
        pricingConditions
      }
      insurers
      location {
        lat
        lon
      }
      address {
        address
      }
      email
      phoneNumber
      consultationId
    }
  }
`;
